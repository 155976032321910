/* You can add global styles to this file, and also import other style files */
@import "~nouislider/distribute/nouislider.min.css";
@import "./variables";
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

html, body { height: 100%; }
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.scrollbar {
  scrollbar-width: thin;          /* "auto" or "thin"  */
  scrollbar-color: grey lightgrey;   /* scroll thumb & track */ 
}
.scrollbar::-webkit-scrollbar {
  width: 8px;   /* width of the entire scrollbar */
  height: 8px;
}
.scrollbar::-webkit-scrollbar-track {
  background: lightgrey;    /* color of the tracking area */
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: grey;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 2px solid grey;  /* creates padding around scroll thumb */
}
.mat-drawer-inner-container {
  scrollbar-width: thin !important;          /* "auto" or "thin"  */
  scrollbar-color: grey lightgrey !important;   /* scroll thumb & track */ 
}
.mat-drawer-inner-container::-webkit-scrollbar {
  width: 8px !important;   /* width of the entire scrollbar */
  height: 8px !important;
}
.mat-drawer-inner-container::-webkit-scrollbar-track {
  background: lightgrey !important;    /* color of the tracking area */
}
.mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background-color: grey !important;    /* color of the scroll thumb */
  border-radius: 20px !important;       /* roundness of the scroll thumb */
  border: 2px solid grey !important;  /* creates padding around scroll thumb */
}
.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  min-width: 1.75rem !important;
  line-height: 2rem !important;
  padding: 0 0.625rem !important;
  border-radius: $global-border-radius !important;
  min-height: 3rem !important;
}
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-size: 0.8125rem !important;
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  height: 0 !important;
}
.mat-form-field-appearance-fill:not(.mat-form-field-disabled) .mat-form-field-flex:hover ~ .mat-form-field-underline .mat-form-field-ripple {
  opacity: 0 !important;
}
.mat-form-field-appearance-fill .mat-form-field-ripple {
  height: 0 !important;
}
.mat-expansion-panel-header {
  padding: 0 1rem !important;
  height: 2.5rem !important;
}
.mat-expansion-panel-body {
  padding: 0 1rem !important;
}
th.mat-header-cell {
  border-bottom-width: 0px !important;
}
th.mat-sort-header {
  border-bottom-width: 0px !important;
}
.mat-option {
  font-size: 13.33px !important;
}
.mat-select-search-clear {
  min-height: 2rem !important;
}