// Import library functions for theme creation.
@use '~@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin explore-filter-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  
  $bgcolor: mat.get-color-from-palette($background, 'background');
  $text-color: mat.get-color-from-palette($foreground, 'text');
  $accent-color: mat.get-color-from-palette($accent);

  .container-modal {
    background-color: darken($bgcolor, 2);
  }
  .left-bar,
  .right-bar {
    background-color: darken($bgcolor, 2);
  }

  .left-bar {
    .apply-filter-section {
      background-color: darken($bgcolor, 2);
    }
  }

  .filter-component {
    .range-number-input {
      border-width: 1px;
      border-color: transparent;
      border-style: solid;
      background-color: rgba($bgcolor, 0.5);
      transition: all .2s ease-in-out;
      
      &:hover, &:focus {
        border-color: $accent-color;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, .2);
      }
  
      &:focus {
        background-color: $bgcolor;
      }
    }
  }
}